import React from "react"
import pixelFrame from "../assets/pixel-frame-square.png"
import { getImage, GatsbyImage } from "gatsby-plugin-image"

const ExperimentItem = ({ data }) => {
  return (
    <div
      style={{
        maskImage: `url(${pixelFrame})`,
        WebkitMaskImage: `url(${pixelFrame})`,
        WebkitMaskRepeat: "no-repeat",
        WebkitMaskPosition: "center",
      }}
    >
      <GatsbyImage image={getImage(data.cover.localFile)} alt="" />
      <img src={data.video_link} alt={data.cover.alternativeText}></img>

      <a href={data.project_link}>
        <div className="title-container">
          <div className="title-wrapper">
            <h4>{data.title}</h4>
            <h5>{data.subtitle}</h5>
          </div>
        </div>
      </a>
    </div>
  )
}

export default ExperimentItem
