/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { useLocation } from "@reach/router"

function Seo({
  description,
  lang,
  meta,
  title,
  image,
  imageAlt,
  preventIndexing,
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
            defaultImage
            defaultImageAlt
          }
        }
      }
    `
  )
  const { pathname } = useLocation()
  const defaultTitle = site.siteMetadata?.title
  const robotsMeta = [
    {
      name: `robots`,
      content: `noindex`,
    },
    {
      name: `googlebots`,
      content: `noindex`,
    },
  ]

  const seo = {
    title: title || site.siteMetadata.title,
    description: description || site.siteMetadata.description,
    siteUrl: `${site.siteMetadata.siteUrl}${pathname}`,
    image: image || site.siteMetadata.defaultImage,
    siteName: site.siteMetadata.title,
    imageAlt: imageAlt || site.siteMetadata.defaultImageAlt,
    preventIndexing: preventIndexing ? robotsMeta : [],
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={seo.title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      meta={[
        {
          name: `description`,
          content: seo.description,
        },
        {
          property: `og:title`,
          content: seo.title,
        },
        {
          property: `og:description`,
          content: seo.description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: seo.image,
        },
        {
          property: `og:image:alt`,
          content: seo.imageAlt,
        },
        {
          property: `og:url`,
          content: seo.siteUrl,
        },
        {
          property: `og:site_name`,
          content: seo.siteName,
        },
        {
          property: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          property: `twitter:image:alt`,
          content: seo.imageAlt,
        },
      ]
        .concat(meta)
        .concat(seo.preventIndexing)}
    />
  )
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default Seo
