import React from "react"
import ExperimentItem from "../components/ExperimentItem"
import Seo from "../components/seo"
import { useStaticQuery, graphql } from "gatsby"

const Experiments = () => {
  const data = useStaticQuery(graphql`
    query experimentListQuery {
      strapiExperimentPage {
        description
        seo {
          metaTitle
          metaDescription
          preventIndexing
          sharedImage {
            alt
            media {
              url
            }
          }
        }
      }
      allStrapiExperiment(sort: { fields: [date_completed], order: DESC }) {
        nodes {
          title
          subtitle
          project_link
          video_link
          cover {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 300)
              }
            }
          }
        }
      }
    }
  `)
  const strapiSeo = data.strapiExperimentPage.seo
  return (
    <>
      <Seo
        title={strapiSeo.metaTitle}
        description={strapiSeo.metaDescription}
        image={strapiSeo.sharedImage.media.url}
        imageAlt={strapiSeo.sharedImage.alt}
      />
      <div className="page-content">
        <header className="upper-content">
          <div className="header-content">
            <h1>Experiments</h1>
            <p>{data.strapiExperimentPage.description}</p>
          </div>
        </header>
        <main className="lower-content">
          <div className="item-container">
            {data.allStrapiExperiment.nodes.map((item, index) => {
              return <ExperimentItem data={item} key={index} />
            })}
          </div>
        </main>
      </div>
    </>
  )
}

export default Experiments
